import { useState } from "react";
import Input from "../../components/preview-input/input";
import usePreviewStore from "../../store/preview-store";
import { useLocation } from "react-router-dom";

function SoffitReplacementSelection() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const hasSheet = queryParams.get('hasSheet');
  const isDisabled =(hasSheet === 'true');
  const { previewData,  setFormData } = usePreviewStore();
  const soffitReplacement = previewData.soffit_replacement;
  const handleInputChange = (value) => {
    setFormData({ 
      ...previewData,
      soffit_replacement: value
     });
  };
  const handleInputChange2 = (field) => (e) => {
    setFormData({
      ...previewData,
      [field]: e.target.value,
    });
  };
  return (
    <div className="k-d-flex k-flex-wrap k-align-items-center k-gap-5 k-sm-gap-2 k-mt-2 k-pl-2">
      <div className="k-sm-w-100">
      <h4 className="k-sm-m-0">Soffit Replacement:</h4>
      </div>

      <div >
        <label className="k-font-light k-flex-basis-auto k-d-flex k-m-0">
          <input
          disabled={isDisabled}
          style={{marginRight:"10px"}}
            type="radio"
            name="soffitReplacement"
            value="Full"
            checked={soffitReplacement === "Full"}
            onChange={() => handleInputChange("Full")}
          />
          Full
        </label>
      </div>

      <div>



        <label className="k-font-light k-flex-basis-auto k-d-flex k-m-0">
          <input
          disabled={isDisabled}
          style={{marginRight:"10px"}}
            type="radio"
            name="soffitReplacement"
            value="Partial"
            checked={soffitReplacement === "Partial"}
            onChange={() => handleInputChange("Partial")}
          />
          Partial
        </label>
        </div>
        <div className="k-d-flex k-flex-wrap k-sm-m-0">

        <h4 className="k-m-0 k-font-light">Total Footage:</h4>

        <div className="k-pos-relative k-left-2">
          <Input
          type="number"
          value={previewData.soffit_total_footage}
          onChange={handleInputChange2("soffit_total_footage")}
          />
        </div>
      </div>
      </div>
  );
}

export default SoffitReplacementSelection;
