import { Button } from "@progress/kendo-react-buttons";
import React, { useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";
import scanButton from "../../assets/images/scan-button.png";
import { useRouter } from "../routes/hooks/use-router";
import "./camra.scss";
import styles from "./Camra.module.scss";
import image from "../../assets/images/test.jpg";
import usePreviewStore from "../../store/preview-store";
import useOrderStore from "../../store/order-store";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import axios from "axios";
// import jsQR from "jsqr";

const videoConstraints = {
  facingMode: "environment", // Use "environment" for rear camera
};

const CameraCapture = () => {
  const webcamRef = useRef(null);
  const router = useRouter();
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const { id } = useParams();
  const { leadId } = useParams();
  const [url, setUrl] = React.useState(null);
  const [loading, setLoading] = useState(false);
  const { previewData, setFormData } = usePreviewStore();
  const { orderData, setFormData: setOrderFormData } = useOrderStore();
  const capturePhoto = React.useCallback(async () => {
    const imageSrc = webcamRef.current.getScreenshot();
    const imageFile = base64ToFile(imageSrc, "image.png");
    console.log(imageSrc, "image i click");
    console.log(imageFile, "image i click");

    handleSubmittoApi(imageFile);
    setUrl(imageSrc);
  }, [webcamRef]);
  function base64ToFile(base64String, fileName) {
    const byteString = atob(base64String.split(",")[1]);
    const mimeType = base64String.match(/data:(.*?);base64/)[1];
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uintArray = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      uintArray[i] = byteString.charCodeAt(i);
    }

    const file = new File([uintArray], fileName, { type: mimeType });
    return file;
  }

  const onUserMedia = (e) => {
    console.log(e);
  };

  const handleSubmittoApi = async (selectedFile) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("file", selectedFile);
    try {
      const response = await axios.post(
        `http://scanapi.mhalt.com/upload/gutter-sheet`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const { data } = response;
      console.log("🚀 ~ onSubmit ~ response:", data);
      const measurementKeys = Object.keys(data.Measurements.Columns["0To12ft"]);
      const measurements = measurementKeys.map((key) => ({
        firstStory: data.Measurements.Columns["0To12ft"][key] || "",
        secondStory: data.Measurements.Columns["12To24ft"][key] || "",
        thirdStory: data.Measurements.Columns["Over24ft"][key] || "",
      }));

      const porch_ceiling = Array(5).fill({
        location: "",
        length: "",
        width: "",
        squareFeet: "",
      });

      const prsa = porch_ceiling.map((item, index) => {
        return {
          location: data?.PorchCeiling[index]?.Location || "",
          length: data?.PorchCeiling[index]?.Length || "",
          width: data?.PorchCeiling[index]?.Width || "",
          squareFeet: data?.PorchCeiling[index]?.SquareFootage || "",
        };
      });

      const previewValues = {
        customer: data.CustomerDetails.Customer || "",
        city: data.CustomerDetails.City || "",
        st: data.CustomerDetails.State || "",
        job: data.CustomerDetails.JobNumber || "",
        measurements: measurements,
        roof_type: data.RoofAndGutterDetails.RoofType || "Asphalt Shingle",
        color: data.RoofAndGutterDetails.Color || "WHITE",
        gutter_type: data.RoofAndGutterDetails.GutterType || "FS",
        gutter_size: data.RoofAndGutterDetails.GutterSize || "4",
        total_gutter_footage: data.RoofAndGutterDetails.TotalGutterFootage || 0,
        downspout:
          data.RoofAndGutterDetails.DownspoutDetails.DownspoutSize || "2x3",
        downspout_color:
          data.RoofAndGutterDetails.DownspoutDetails.DownspoutColor || "WHITE",
        downspout_footage:
          data.RoofAndGutterDetails.DownspoutDetails.DownspoutFootage || 0,
        aelbows: data.RoofAndGutterDetails.Elbows.NumberAElbows || "",
        belbows: data.RoofAndGutterDetails.Elbows.NumberBElbows || "",
        roundElbows: data.RoofAndGutterDetails.Elbows.NumberRoundElbows || "",
        offsetElbows: data.RoofAndGutterDetails.Elbows.NumberOffsetElbows || "",
        outside: data.RoofAndGutterDetails.Miters.Outside || "",
        gutter_downspout_elbows: data.RoofAndGutterDetails.TotalFootage,
        inside: data.RoofAndGutterDetails.Miters.Inside || "",
        rights: data.RoofAndGutterDetails.EndCaps.Rights || "",
        lefts: data.RoofAndGutterDetails.EndCaps.Lefts || "",
        savers: data.RoofAndGutterDetails.AdditionalParts.SpoutSavers || "",
        straps: data.RoofAndGutterDetails.AdditionalParts.RoofStraps || "",
        wedges: data.RoofAndGutterDetails.AdditionalParts.Wedges || "",
        partial_install: data.RoofAndGutterDetails.PartialInstall || "Yes",
        fascia_replacement: data.FasciaReplacement.ReplacementType || "Full",
        fascia_total_footage: data.FasciaReplacement.TootalFootage || 0,
        fascia_size: data.FasciaReplacement.Size.CircleOption || "1x4",
        fascia_other_size: data.FasciaReplacement.Size.SizeValue,
        fascia_other: data.FasciaReplacement.wrapType.CircleOption || "Painted",
        fascia_other_value: data.FasciaReplacement.wrapType.WrapTypeValue || "",
        fascia_type: data.FasciaReplacement.Type || "",
        fascia_wrap: data.FasciaReplacement.NewWrapFootage || 0,
        soffit_replacement: data.SoffitReplacement.ReplacementType || "Full",
        soffit_total_footage: data.SoffitReplacement.TotalFootage || 0,
        soffit_other: data.SoffitReplacement.Material.CircleOption || "Wood",
        soffit_other_value: data.SoffitReplacement.Material.MaterialValue || "",
        soffit_color: data.SoffitReplacement.Color || "None",
        porch_ceiling: prsa,
        files: [],
        images: [],
      };
      setLoading(false);
      setFormData(previewValues);
      router.push(`/preview/${id}?hasSheet=false&leadId=${leadId}`);
    } catch (error) {
      setLoading(false);
      toast.error(
        "Your choosen image is not recognized by the system please select different image"
      );
    }
  };


  const captureImage = () => {
    const canvas = canvasRef.current;
    const video = videoRef.current;
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    const context = canvas.getContext("2d");
    context.drawImage(video, 0, 0, canvas.width, canvas.height);
    const image = canvas.toDataURL("image/png");

    const imageFile = base64ToFile(image, "image.png");
    console.log(image, "image i click");
    console.log(imageFile, "image i click");

    handleSubmittoApi(imageFile);
    setUrl(image);
  };



  useEffect(() => {
    startCamera();
  }, []);
  const startCamera = async () => {
    const facingMode = window.innerWidth > 500 ? "user" : "environment";
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        // video: { facingMode: { exact: "environment" } },
        video: { facingMode: { exact: facingMode} },
      });
      videoRef.current.srcObject = stream;
      videoRef.current.play(); // Start the video stream in the background
    } catch (error) {
      console.error("Error accessing camera: ", error);
    }
  };

  return (
    <>
      {/* <Webcam
        ref={webcamRef}
        audio={true}
        screenshotFormat="image/jpeg"
        videoConstraints={videoConstraints}
        onUserMedia={onUserMedia}
      />
      <button onClick={capturePhoto}>Capture</button>
      <button onClick={() => setUrl(null)}>Refresh</button>
      {url && (
        <div>
          <img src={url} alt="Screenshot" />
        </div>
      )} */}

      {url ? (
        <div>
          <h2>Scaning...</h2>
          <div
            className="k-my-10 k-rounded-lg k-p-4 "
            style={{ backgroundColor: "#F3F3F3" }}
          >
            {/* <img src={url} alt="Screenshot"/> */}
            <div id="dup-scanning-files">
              <div class="scanning-wrapper">
                <div class="top-corners"></div>
                <div class="bottom-corners"></div>
                <div class="file-types">
                  <img
                    style={{ objectFit: "contain", height: "500px" }}
                    src={url}
                    alt="Screenshot"
                  />
                </div>
                <div class="scanner"></div>
              </div>
            </div>
          </div>
          {/* <div className="k-w-full k-d-flex k-justify-content-end k-mb-8">
     <Button onClick={() => setUrl(null)} primary={true} className="k-button k-mr-2" size="large">
         Cancel
        </Button>
     <Button onClick={()=>router.push("/scan-preview")} primary={true} className="k-button-green k-mr-2" size="large">
         Next
        </Button>
     </div> */}
        </div>
      ) : (
        <div>
          <h2 className="k-py-4 k-font-bold k-m-0">Scan</h2>
          <h4 className="k-font-light k-m-0">
            Please point the camera at the page
          </h4>
          <div
            className="k-my-10 k-rounded-lg k-p-4 k-d-flex k-justify-content-center"
            style={{ backgroundColor: "#F3F3F3", height: "100%" }}
          >
            <div className={styles.corner_border}>
              <div className={styles.top_right}></div>
              <div className={styles.bottom_left}></div>
              {/* <Webcam
                // style={{height:"95%",width:"94%",paddingLeft:"9px",paddingTop:"10px"}}
                className={styles.webcam}
                ref={webcamRef}
                screenshotQuality={1}  
                audio={true}
                screenshotFormat="image/jpeg"
                videoConstraints={videoConstraints}
                onUserMedia={onUserMedia}
                
              /> */}
               <video playsInline autoPlay ref={videoRef} style={{height:"95%",width:"94%",paddingLeft:"9px",paddingTop:"10px"}}/>
      <canvas ref={canvasRef} style={{ display: "none" }} />

              <div style={{ marginTop: "-30px" }} className="k-text-center">
                <img
                  onClick={captureImage}
                  height={50}
                  src={scanButton}
                  alt="button"
                  style={{ cursor: "pointer", position: "relative" }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CameraCapture;
