export const getDataForSf = (sfData) => {
  let fourPrimaryColors = ["WHITE", "CLAY", "LINEN", "MUSKET BROWN"];

  const inCLudeInPrimaryColors = fourPrimaryColors.includes(
    sfData?.color?.toUpperCase() || "WHITE"
  );
  ///retail price
  const firstStoryTotal1Retail =
    (sfData.measurements[6]?.firstStory[0] || 0) * 38;
  const firstStoryTotal2Retail =
    (sfData.measurements[6]?.firstStory[1] || 0) * 39;
  const secondStoryTotal1Retail =
    (sfData.measurements[6]?.secondStory[0] || 0) * 40;
  const secondStoryTotal2Retail =
    (sfData.measurements[6]?.secondStory[1] || 0) * 41;
  const thirdStoryTotal1Retail =
    (sfData.measurements[6]?.thirdStory[0] || 0) * 42;
  const thirdStoryTotal2Retail =
    (sfData.measurements[6]?.thirdStory[1] || 0) * 43;

  const firstStoryRRTotal1Retail =
    (sfData.measurements[7]?.firstStory[0] || 0) * 4;
  const firstStoryRRTotal2Retail =
    (sfData.measurements[7]?.firstStory[1] || 0) * 4;
  const secondStoryRRTotal1Retail =
    (sfData.measurements[7]?.secondStory[0] || 0) * 4;
  const secondStoryRRTotal2Retail =
    (sfData.measurements[7]?.secondStory[1] || 0) * 4;
  const thirdStoryRRTotal1Retail =
    (sfData.measurements[7]?.thirdStory[0] || 0) * 4;
  const thirdStoryRRTotal2Retail =
    (sfData.measurements[7]?.thirdStory[1] || 0) * 4;
  const versaMaxQuantityRetail = Number(sfData.versa_max_quantity) * 55;
  const fatnerTypeRetail =
    sfData.mountingType == "T Straps" || sfData.mountingType == "Rival Straps"
      ? Number(sfData.mountingLength) * 3
      : Number(sfData.mountingLength) * 1;
  const cleanSealReinforceRetail = Number(sfData.csrValue) * 7;
  const ofInsideCornersRetail = Number(sfData.insideCorners) * 25;
  const ofOutsideCornersRetail = Number(sfData.outsideCorners) * 25;
  const typeRetail = Number(sfData.existingProtectionTypeValue) * 3;
  const footageRetail = Number(sfData.extensionFootage) * 25;
  const unitsRetail = Number(sfData.units) * 50;
  const ofAElbowsRetail =
    Number(sfData.AElbows) * (inCLudeInPrimaryColors ? 21 : 22);
  const ofBElbowsRetail =
    Number(sfData.BElbows) * (inCLudeInPrimaryColors ? 21 : 22);
  const sizesRetail = sfData.sizes.map((item) => ({
    label: item.label,
    values: item.values.map(
      (value) => parseInt(value) * (inCLudeInPrimaryColors ? 21 : 22)
    ),
  }));
  //cost price

  const firstStoryTotal1Cost =
    (sfData.measurements[6]?.firstStory[0] || 0) * 16;
  const firstStoryTotal2Cost =
    (sfData.measurements[6]?.firstStory[1] || 0) * 16;
  const secondStoryTotal1Cost =
    (sfData.measurements[6]?.secondStory[0] || 0) * 16;
  const secondStoryTotal2Cost =
    (sfData.measurements[6]?.secondStory[1] || 0) * 16;
  const thirdStoryTotal1Cost =
    (sfData.measurements[6]?.thirdStory[0] || 0) * 16;
  const thirdStoryTotal2Cost =
    (sfData.measurements[6]?.thirdStory[1] || 0) * 16;

  const firstStoryRRTotal1Cost =
    (sfData.measurements[7]?.firstStory[0] || 0) * 2;
  const firstStoryRRTotal2Cost =
    (sfData.measurements[7]?.firstStory[1] || 0) * 2;
  const secondStoryRRTotal1Cost =
    (sfData.measurements[7]?.secondStory[0] || 0) * 2;
  const secondStoryRRTotal2Cost =
    (sfData.measurements[7]?.secondStory[1] || 0) * 2;
  const thirdStoryRRTotal1Cost =
    (sfData.measurements[7]?.thirdStory[0] || 0) * 2;
  const thirdStoryRRTotal2Cost =
    (sfData.measurements[7]?.thirdStory[1] || 0) * 2;

  const cleanSealReinforceCost = Number(sfData.csrValue) * 3.5;
  const ofInsideCornersCost = Number(sfData.insideCorners) * 12.5;
  const ofOutsideCornersCost = Number(sfData.outsideCorners) * 12.5;
  const typeCost = Number(sfData.existingProtectionTypeValue) * 1.75;
  const footageCost =
    Number(sfData.extensionFootage) * (inCLudeInPrimaryColors ? 10.5 : 11);
  const unitsCost = Number(sfData.units) * 25;
  const ofAElbowsCost =
    Number(sfData.AElbows) * (inCLudeInPrimaryColors ? 10.5 : 11);
  const ofBElbowsCost =
    Number(sfData.BElbows) * (inCLudeInPrimaryColors ? 10.5 : 11);
  const versaMaxQuantityCost = Number(sfData.versa_max_quantity) * 27.5;
  const fatnerTypeCost =
    sfData.mountingType == "T Straps" || sfData.mountingType == "Rival Straps"
      ? Number(sfData.mountingLength) * 3
      : Number(sfData.mountingLength) * 1;

  const sizesCost = sfData.sizes.map((item) => ({
    label: item.label,
    values: item.values.map((value) => parseInt(value) * 10.5),
  }));
  function convertArrayToNumbers(arr) {
    return arr.map(Number);
}
function convertMeasurementsToNumbers(measurements) {
  return measurements.map(measurement => {
      let convertedMeasurement = {};

      // Loop through the object's keys and convert array values
      for (let key in measurement) {
          if (Array.isArray(measurement[key])) {
              convertedMeasurement[key] = measurement[key].map(Number);
          } else {
              convertedMeasurement[key] = measurement[key]; // Keep non-array values unchanged
          }
      }

      return convertedMeasurement;
  });
}
function convertSizesToNumbers(sizes) {
  return sizes.map(size => ({
      ...size, // Copy existing properties
      values: size.values.map(Number) // Convert values array to numbers
  }));
}

  const objectForSf = {
    ...sfData,
    AElbows:Number(sfData.AElbows) || 0,
    BElbows:Number(sfData.BElbows) || 0,
    csrValue:Number(sfData.csrValue) || 0,
    cleanSealReinforceQty:Number(sfData.csrValue) || 0,
    csr_value:Number(sfData.csr_value) || 0,
    extensionFootage:Number(sfData.extensionFootage) || 0,
    extensionFootage:Number(sfData.extensionFootage) || 0,
    insideCorners:Number(sfData.insideCorners) || 0,
    ladders:Number(sfData.ladders) || 0,
    mountingLength:Number(sfData.mountingLength) || 0,
    outsideCorners:Number(sfData.outsideCorners) || 0,
    spoutSaverQuantity:Number(sfData.spoutSaverQuantity) || 0,
    totalFootage:Number(sfData.totalFootage) || 0,
    units:Number(sfData.units) || 0,
    gutterOpeningWidth:convertArrayToNumbers(sfData.gutterOpeningWidth),
    measurements:convertMeasurementsToNumbers(sfData.measurements),
    sizes:convertSizesToNumbers(sfData.sizes),
    versaMaxQuantity:sfData.versaMaxValue,
    color:['White','Beige','Clay','Gray'].includes(sfData.color) ? sfData.color : 'White',
    existingProtectionTypeValueQty:sfData.existingProtectionTypeValue,
    roofType:[sfData.roofType],
    retailPricing: {
      firstStoryTotal1: firstStoryTotal1Retail || 0,
      firstStoryTotal2: firstStoryTotal2Retail || 0,
      secondStoryTotal1: secondStoryTotal1Retail || 0,
      secondStoryTotal2: secondStoryTotal2Retail || 0,
      thirdStoryTotal1: thirdStoryTotal1Retail || 0,
      thirdStoryTotal2: thirdStoryTotal2Retail || 0,
      firstStoryRAndRTotal1: firstStoryRRTotal1Retail || 0,
      firstStoryRAndRTotal2: firstStoryRRTotal2Retail || 0,
      secondStoryRAndRTotal1: secondStoryRRTotal1Retail || 0,
      secondStoryRAndRTotal2: secondStoryRRTotal2Retail || 0,
      thirdStoryRAndRTotal1: thirdStoryRRTotal1Retail || 0,
      thirdStoryRAndRTotal2: thirdStoryRRTotal2Retail || 0,
      cleanSealReinforce: cleanSealReinforceRetail || 0,
      ofInsideCorners: ofInsideCornersRetail || 0,
      ofOutSideCorners: ofOutsideCornersRetail || 0,
      typeCost: typeRetail || 0,
      extensionsNeededFootage: footageRetail || 0,
      units: unitsRetail || 0,
      ofAElbows: ofAElbowsRetail || 0,
      ofBElbows: ofBElbowsRetail || 0,
      sizes: sizesRetail,
      versaMaxQuantity: versaMaxQuantityRetail || 0,
      fastenerType: fatnerTypeRetail || 0,
      isColor: inCLudeInPrimaryColors,
    },
    costPricing: {
      firstStoryTotal1: firstStoryTotal1Cost || 0,
      firstStoryTotal2: firstStoryTotal2Cost || 0,
      secondStoryTotal1: secondStoryTotal1Cost || 0,
      secondStoryTotal2: secondStoryTotal2Cost || 0,
      thirdStoryTotal1: thirdStoryTotal1Cost || 0,
      thirdStoryTotal2: thirdStoryTotal2Cost || 0,
      firstStoryRAndRTotal1: firstStoryRRTotal1Cost || 0,
      firstStoryRAndRTotal2: firstStoryRRTotal2Cost || 0,
      secondStoryRAndRTotal1: secondStoryRRTotal1Cost || 0,
      secondStoryRAndRTotal2: secondStoryRRTotal2Cost || 0,
      thirdStoryRAndRTotal1: thirdStoryRRTotal1Cost || 0,
      thirdStoryRAndRTotal2: thirdStoryRRTotal2Cost || 0,
      cleanSealReinforce: cleanSealReinforceCost || 0,
      ofInsideCorners: ofInsideCornersCost || 0,
      ofOutSideCorners: ofOutsideCornersCost || 0,
      typeCost: typeCost || 0,
      extensionsNeededFootage: footageCost || 0,
      units: unitsCost || 0,
      ofAElbows: ofAElbowsCost || 0,
      ofBElbows: ofBElbowsCost || 0,
      sizes: sizesCost,
      versaMaxQuantity: versaMaxQuantityCost || 0,
      fastenerType: fatnerTypeCost || 0,
      isColor: inCLudeInPrimaryColors,
    },
  };
  return objectForSf;
};


