import React, { useRef, useEffect, useState } from 'react';
import { ReactSketchCanvas } from 'react-sketch-canvas';
import { ChromePicker } from 'react-color'; // Color picker component
import styles from './Drawing.module.scss'
import { FaPencilAlt } from "react-icons/fa";
import { CiEraser } from "react-icons/ci";
import { FaUndo } from "react-icons/fa";
import { FaRedo } from "react-icons/fa";
import { TbWashDrycleanOff } from "react-icons/tb";
import { Upload } from '@progress/kendo-react-upload';
import { HOST_API } from '../../config-global';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
import { Loader } from '@progress/kendo-react-indicators';
import { toast } from 'react-toastify';
const Drawing = ({updater}) => {
  const canvasRef = useRef(null);
  const [defaultDrawing, setDefaultDrawing] = useState(null); // Holds the default drawing data
  const [color, setColor] = useState('#000000'); // Default pen color
  const [isErasing, setIsErasing] = useState(false); // Eraser mode toggle
  const [files, setFiles] = useState([]); // Initialize an empty array for files
  const [bluePrint,setBluePrint]=useState(null)
  const [loading,setLoading]=useState(false)
  const handleAdd = async (event) => {
    setLoading(true)
    const newFiles = event.affectedFiles.map((fileWrapper) => fileWrapper.getRawFile()); // Convert to File object
    console.log(newFiles[0])
    // setFiles((prevFiles) => [...prevFiles, ...newFiles]); // Add to state
    // updater([...files, ...newFiles])
    const formData = new FormData();
  formData.append("files", newFiles[0]);

  try {
    const response = await axios.post(
      `${HOST_API}/blueprint/v1/create`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    console.log("🚀 File Uploaded Successfully:", response.data);
    let {data}=response.data
    setBluePrint(data)
  } catch (error) {
    toast.error('Something went wrong. Please try again later')
    console.error("File upload failed:", error);
    setBluePrint(null)
  } finally{
    setLoading(false)
  }
};
const handlePostImage=()=>{

}
const handleRemove = (event) => {
    const removedFile = event.affectedFiles[0].getRawFile(); // Get the raw file object
    setFiles((prevFiles) =>
        prevFiles.filter((file) => file.name !== removedFile.name) // Compare by file name
    );
    updater(files.filter((file) => file.name !== removedFile.name))
};

 
  // Load default drawing when component mounts
//   useEffect(() => {
//     fetchDefaultDrawing();
//   }, []);

//   // Fetch default drawing data from API
//   const fetchDefaultDrawing = async () => {
//     try {
//       const response = await axios.get('/api/get-default-drawing'); // Adjust API endpoint
//       if (response.data) {
//         setDefaultDrawing(response.data);
//       }
//     } catch (error) {
//       console.error('Error fetching default drawing:', error);
//     }
//   };

  // Save drawing to API
//   const handleSaveDrawing = async () => {
//     try {
//       const drawingData = await canvasRef.current.exportImage('png'); // Or 'jpeg' or 'svg' format
//       await axios.post('/api/save-drawing', { imageData: drawingData }); // Adjust API endpoint
//       alert('Drawing saved successfully!');
//     } catch (error) {
//       console.error('Error saving drawing:', error);
//     }
//   };

  // Handle color change
  const handleColorChange = (color) => {
    setColor(color.hex);
    canvasRef.current.eraseMode(false); // Switch to drawing mode on color change
    setIsErasing(false);
  };

  // Toggle Eraser Mode
  const toggleEraser = () => {
    setIsErasing(!isErasing);
    canvasRef.current.eraseMode(!isErasing);
  };

  return (
    <div className={`${styles.uploaderContainer}`}>
      {/* <ReactSketchCanvas
        ref={canvasRef}
        width="100%"
        style={{border:"none"}}
        height="200px"
        strokeWidth={4}
        strokeColor={color}
        onReady={() => {
          if (defaultDrawing) {
            canvasRef.current.loadPaths(defaultDrawing);
          }
        }}
      />
      <div>
        <div>
        <button onClick={toggleEraser} className={styles.drawing_button}>
            {
                isErasing ? <FaPencilAlt/> :
                <CiEraser style={{fontSize:"2rem"}}/>
            }
        </button>
        </div>
        <div>
        <button onClick={() => canvasRef.current.undo()} className={styles.drawing_button}>
        <FaUndo/>
        </button>
        </div>
       <div>
       <button onClick={() => canvasRef.current.redo()} className={styles.drawing_button}>
        <FaRedo/>
        </button>
       </div>
        <div>
        <button onClick={() => canvasRef.current.clearCanvas()} className={styles.drawing_button}>
        <TbWashDrycleanOff/>
        </button>
        </div>
      </div> */}
      <Upload
                batch={false}
                multiple={false}
                defaultFiles={[]}
                withCredentials={false}
                saveUrl={'https://demos.telerik.com/kendo-ui/service-v4/upload/save'}
                removeUrl={'https://demos.telerik.com/kendo-ui/service-v4/upload/remove'}
                onAdd={handleAdd} // Handle file addition
                onRemove={handleRemove} // Handle file removal
                showFileList={false}
            />
         {bluePrint && !loading &&    <div style={{display:"flex",justifyContent:"center",marginTop:"10px"}}>
                <img src={bluePrint} alt='image'/>
            </div>}
            {loading &&   <div className='k-display-flex k-justify-content-center k-mt-10'>
              <Loader themeColor='success' size='medium' type={'infinite-spinner'} /></div>}
    </div>
  );
};

export default Drawing;
