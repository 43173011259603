import { colorList } from "../../utils/formats";
let fourPrimaryColors = ["WHITE", "CLAY", "LINEN", "MUSKET BROWN"];
export const getDataForSalesFor = (previewData, leadId) => {
  //caluclations for sales force
  const inCLudeInPrimaryColors =
    fourPrimaryColors.includes(previewData?.color?.toUpperCase() || "WHITE") ||
    fourPrimaryColors.includes(
      previewData?.downspout_color?.toUpperCase() || "WHITE"
    );

  //Cost Price
  let firstStoryTotalCost =
    previewData.measurements
      .slice(0, 6)
      .reduce((sum, s) => sum + (s.firstStory || 0), 0) *
    (inCLudeInPrimaryColors ? 10.5 : 11.5);
  let secondStoryTotalCost =
    previewData.measurements
      .slice(0, 6)
      .reduce((sum, s) => sum + (s.secondStory || 0), 0) *
    (inCLudeInPrimaryColors ? 10.5 : 11.5);
  let thirldSotryTotalCost =
    previewData.measurements
      .slice(0, 6)
      .reduce((sum, s) => sum + (s.thirdStory || 0), 0) *
    (inCLudeInPrimaryColors ? 10.5 : 11.5);

  let fasciaTotalFootageCost = previewData?.fascia_total_footage * 12.5;
  let sofitTotalFootageCost = previewData?.soffit_total_footage * 12.5;

  let totalGutterFootageCost =
    Number(previewData.gutter_size) == 7
      ? previewData.total_gutter_footage * 14.5
      : previewData.total_gutter_footage *
        (inCLudeInPrimaryColors ? 10.5 : 11.5);

  let totalFtOfNewWrapCost = Number(previewData.fascia_wrap) * 9;
  let downSpoutFootageCost =
    Number(previewData.downspout_footage) *
    (inCLudeInPrimaryColors ? 10.5 : 11.5);
  let aElbowCost =
    Number(previewData.aelbows) * (inCLudeInPrimaryColors ? 10.5 : 11.5);
  let bElbowCost =
    Number(previewData.belbows) * (inCLudeInPrimaryColors ? 10.5 : 11.5);
  let ofWedgesCost = Number(previewData.wedges) * 1.75;
  let gutterSizeCost = Number(previewData.gutter_size) * 14.5;

  let totalGutterDownspoutElbowsCost =
    downSpoutFootageCost +
    aElbowCost +
    bElbowCost +
    Number(previewData.offsetElbows) +
    Number(previewData.roundElbows) +
    totalGutterFootageCost;

  let porchCeilingCost = previewData?.porch_ceiling.map((item) => ({
    ...item,
    squareFeet: item.squareFeet * 8,
  }));

  //Retail Price

  let firstStoryTotalRetail =
    previewData.measurements
      .slice(0, 6)
      .reduce((sum, s) => sum + (s.firstStory || 0), 0) *
    (inCLudeInPrimaryColors ? 21 : 22);
  let secondStoryTotalRetail =
    previewData.measurements
      .slice(0, 6)
      .reduce((sum, s) => sum + (s.secondStory || 0), 0) *
    (inCLudeInPrimaryColors ? 21 : 22);
  let thirldSotryTotalRetail =
    previewData.measurements
      .slice(0, 6)
      .reduce((sum, s) => sum + (s.thirdStory || 0), 0) *
    (inCLudeInPrimaryColors ? 21 : 22);

  let fasciaTotalFootageRetail = previewData?.fascia_total_footage * 25;

  let soffitTotalFootageRetail = previewData?.fascia_total_footage * 25;
  let totalGutterFootageRetail =
    Number(previewData.gutter_size) == 7
      ? previewData.total_gutter_footage * 29
      : previewData.total_gutter_footage * (inCLudeInPrimaryColors ? 21 : 22);

  let totalFtOfNewWrapRetail = Number(previewData.fascia_wrap) * 18;
  let downSpoutFootageRetail =
    Number(previewData.downspout_footage) * (inCLudeInPrimaryColors ? 21 : 22);
  let aElbowRetail =
    Number(previewData.aelbows) * (inCLudeInPrimaryColors ? 21 : 22);
  let bElbowRetail =
    Number(previewData.belbows) * (inCLudeInPrimaryColors ? 21 : 22);
  let ofWedgesRetail = Number(previewData.wedges) * 3.5;
  let totalGutterDownspoutElbowsRetail =
    downSpoutFootageRetail +
    aElbowRetail +
    bElbowRetail +
    Number(previewData.offsetElbows) +
    Number(previewData.roundElbows) +
    totalGutterFootageRetail;
  let gutterSizeRetail = Number(previewData.gutter_size) * 29;

  let porchCeilingRetail = previewData?.porch_ceiling.map((item) => ({
    ...item,
    squareFeet: item.squareFeet * 16,
  }));

  const subdata = {
    customer: previewData.customer || "",
    city: previewData.city || "",
    street: previewData.st || "",
    leadId: Number(leadId),
    newMeterInside: previewData.newMeterInside || 0,
    newMeterOutSide: previewData.newMeterOutSide || 0,
    // leadId: Math.random().toString(),
    gutter_height:previewData?.gutterHeight || [],
    measurements: previewData.measurements,
    ribbedRoofQuantity:
      previewData?.roof_type?.includes("Ribbed Roof")
        ? Number(previewData.ribbedRoofQuantity)
        : 0,
    roof: {
      sumOfAllFootage:
        Number(previewData?.total_gutter_footage) +
        Number(previewData?.aelbows) +
        Number(previewData?.belbows) +
        Number(previewData?.roundElbows) +
        Number(previewData?.offsetElbows) +
        Number(previewData.downspout_footage),
      roofType: previewData.roof_type || "",
      gutterType:
        previewData.gutter_type == "FS"
          ? "Fascia Style"
          : previewData.gutter_type == "HR"
          ? "Half Round"
          : previewData.gutter_type,
      gutterColor: colorList.includes(
        previewData?.color?.toUpperCase() || "WHITE"
      )
        ? previewData?.color || "WHITE"
        : "WHITE",
      gutterSize: Number(previewData.gutter_size) || 0,
      totalGutterFootage: Number(previewData.total_gutter_footage) || 0,
      downSpout: previewData.downspout || "",
      downSpoutColor: colorList.includes(
        previewData?.downspout_color?.toUpperCase() || "WHITE"
      )
        ? previewData?.downspout_color || "WHITE"
        : "WHITE",

      downSpoutFootage: {
        ofAElbows: Number(previewData.aelbows) || 0,
        ofBElbows: Number(previewData.belbows) || 0,
        ofRoundElbows: Number(previewData.roundElbows) || 0,
        ofOffsetElbows: Number(previewData.offsetElbows) || 0,
        totalFootage: Number(previewData.downspout_footage) || 0,
      },
    },

    misc: {
      ofMitersOutside: Number(previewData.outside) || 0,
      ofMitersInside: Number(previewData.inside) || 0,
      ofEndcapsRights: Number(previewData.rights) || 0,
      ofEndcapsLefts: Number(previewData.lefts) || 0,
      ofSproutSavers: Number(previewData.savers) || 0,
      ofRoofStraps: Number(previewData.straps) || 0,
      ofwedges: Number(previewData.wedges) || 0,
    },
    partialInstall: previewData.partial_install == "Yes" ? true : false,

    fasciaReplacement: {
      type: previewData.fascia_replacement || "",
      totalFootage: Number(previewData.fascia_total_footage) || 0,
      size:
        previewData.fascia_size === "Other"
          ? previewData.fascia_other_size || ""
          : previewData.fascia_size || "",
      productType:
        previewData.fascia_type == "Other"
          ? previewData.fascia_other_value
          : previewData.fascia_type,
      // productDetail:
      //   previewData.fascia_other == "Other"
      //     ? previewData.fascia_other_value
      //     : previewData.fascia_other,
      productDetail:
        previewData.fascia_other,
      totalFtOfNewWrap: previewData.fascia_wrap || 0,
      fascia_color: colorList.includes(
        previewData?.fascia_color?.toUpperCase()
      ) ? previewData?.fascia_color?.toUpperCase() :  "",
    },

    soffitReplacement: {
      replacementType: previewData.soffit_replacement || "",
      soffitType:
        previewData.soffit_other == "Other"
          ? previewData.soffit_other_value
          : previewData.soffit_other,
      soffitColor: colorList.includes(
        previewData?.soffit_color?.toUpperCase()
      ) ? previewData?.soffit_color?.toUpperCase()  : "",
      totalFootage: Number(previewData.soffit_total_footage) || 0,
    },
    porchCeiling: previewData?.porch_ceiling,
    // metalWrappedColor:
    //   previewData.fascia_other == "Metal Wrapped"
    //     ? colorList.includes(
    //         typeof previewData?.metal_wrapped_color == "string"
    //           ? previewData?.metal_wrapped_color?.toUpperCase()
    //           : "None"
    //       )
    //       ? previewData?.metal_wrapped_color || "None"
    //       : "None"
    //     : "None",

    costPrice: {
      inCLudeInPrimaryColors: inCLudeInPrimaryColors,
      firstStoryTotal: firstStoryTotalCost || 0,
      secondStoryTotal: secondStoryTotalCost || 0,
      thirldSotryTotal: thirldSotryTotalCost || 0,
      totalGutterFootage: totalGutterFootageCost || 0,
      downspoutFootage: downSpoutFootageCost || 0,
      aElbows: aElbowCost || 0,
      bElbows: bElbowCost || 0,
      totalGutterDownspoutElbows: totalGutterDownspoutElbowsCost || 0,
      ofWedges: ofWedgesCost || 0,
      fasciaTotalFootage: fasciaTotalFootageCost || 0,
      totalFTOfNewWrap: totalFtOfNewWrapCost || 0,
      soffitTotalFootage: sofitTotalFootageCost || 0,
      porchCeiling: porchCeilingCost,
      gutterSize: gutterSizeCost || 0,
    },
    retailPrice: {
      inCLudeInPrimaryColors: inCLudeInPrimaryColors,
      firstStoryTotal: firstStoryTotalRetail || 0,
      secondStoryTotal: secondStoryTotalRetail || 0,
      thirldSotryTotal: thirldSotryTotalRetail || 0,
      totalGutterFootage: totalGutterFootageRetail || 0,
      downspoutFootage: downSpoutFootageRetail || 0,
      aElbows: aElbowRetail || 0,
      bElbows: bElbowRetail || 0,
      totalGutterDownspoutElbows: totalGutterDownspoutElbowsRetail || 0,
      ofWedges: ofWedgesRetail || 0,
      fasciaTotalFootage: fasciaTotalFootageRetail|| 0,
      totalFTOfNewWrap: totalFtOfNewWrapRetail || 0,
      soffitTotalFootage: soffitTotalFootageRetail || 0,
      porchCeiling: porchCeilingRetail,
      gutterSize: gutterSizeRetail || 0,
      newMeterInside: previewData.newMeterInside * 100,
      newMeterOutSide: previewData.newMeterOutSide * 100,
    },
  };
  return subdata;
};
